<template>
  <div class="hello">
    
    <router-view></router-view>
  </div>
 

</template>

<script>
 import HelloWorld from './components/HelloWorld.vue'  

export default {
  name: 'app',
  components: {
    HelloWorld
  }
}
</script>

<style>
html,body{
  background: #18191a;
  margin: 0;
  padding: 0;
 
}


</style>

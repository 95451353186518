
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'

//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    { 
         path:'/home',
        name:'Main',
        component: () => import('../views/Main'),
        children:[
          {
            path: '/home',
            name: 'Home',
            component: () => import('../views/Home')
          },
          {
            path: '/bankuai',
            name: 'Bankuai',
            component: () => import('../views/Bankuai')
          },
          {
            path: '/Dapan',
            name: 'Dapan',
            component: () => import('../views/Dapan')
          },
          {
            path: '/Gegu',
            name: 'Gegu',
            component: () => import('../views/Gegu')
          },
          {
            path: '/Zixuan',
            name: 'Zixuan',
            component: () => import('../views/Zixuan')
          },
            
         ] 
     }, 
    {
        path:'/login',
        name:'login',
        component: () => import('../views/Login')
    },
    {
        path:'/',
        redirect:'/login'
    }
]

const router = new VueRouter({
    mode:'history',
    
    routes

})

 //添加全局导航守卫
router.beforeEach((to,from,next) => {
  //判断token是否存在
  const token = Cookie.get('token')
  //token不存在，说明当前用户是未登录，应该跳转回登录页login
  if(!token && to.name !== 'login'){
    next({name:'login'})
  }else if(token && to.name == 'login'){ //token存在，跳转到首页
    next({name: 'Home'})
  }else(
    next()
  )
}) 


export default router

 
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import store from '../store/index'
import router from './router/index'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import elementResizeDetectorMaker from "element-resize-detector"
import Cookie from 'js-cookie'

import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'





import axios from 'axios'
import vueaxios from 'vue-axios'

import * as XLSX from 'xlsx';  
import XLSXS from 'xlsx-js-style';
import { saveAs } from 'file-saver'; 

Vue.prototype.$echarts = echarts;


Vue.use(ElementUI)
Vue.use(elementResizeDetectorMaker)
Vue.config.productionTip = false
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

Vue.prototype.$exportToExcel = function (data, fileName = 'export.xlsx') {  
  const ws = XLSX.utils.json_to_sheet(data, { sheetStubs: true });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);

};  


new Vue({
  axios,
  store,
  router,
  echarts,
  vueaxios,
  render: h => h(App),
}).$mount('#app')




axios.defaults.baseURL = 'https://kbupp.cn:443';

